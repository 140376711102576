.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  z-index: 300;
  color: white;
  background-color: black;
  border-top: 1px solid #333;
  padding: 1rem;

  .copy {
    font-size: 0.75rem;
    text-align: center;

    a {
      margin-right: 0.5rem;
      display: inline-flex;    

      &:first-of-type {
        margin-left: 0.5rem;
      }
    }
  }

  a {
    color: white;
    text-decoration: underline;
  }

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    z-index: 300;

  }

  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;

    svg {
      padding: 1rem;
      width: 100%;
      height: 100%;
      fill: black;
    }

  }
}