.static {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
  padding: 1.5rem;
  background-color: #e2e4e6;
  color: #222;  
  flex-grow: 1;

  h1, h2 {
    font-size: 2rem;
    line-height: 1.1;
    margin: 1rem 0;
    font-weight: 400 !important;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.1;
    margin: 1rem 0;
    font-weight: 400;
  }

  p,
  ul,
  ol {
    font-size: 0.875rem;
    line-height: 1.6;
    margin: 0 0 0.5rem 0;

    strong {
      font-weight: 700;
    }
  }

  figure {
    margin: 0;
    padding: 0;
  } 

  img {
    width: 100%;
    height: auto;
    margin: 1rem 0;
  }

  @media (min-width: 1200px) {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    border-radius: 5px;
    padding: 2.5rem;


    h1, h2 {
      font-size: 2.5rem;
      line-height: 1.1;
      margin: 1rem 0;
      font-weight: 400;
    }
    h3 {
      font-size: 2rem;
      line-height: 1.1;
      margin: 1rem 0;
      font-weight: 400;
    }
  
    p,
    ul,
    ol {
      font-size: 1rem;
      line-height: 1.6;
      margin: 0 0 1rem 0;
  
      strong {
        font-weight: 700;
      }
    }

    img {
      width: 100%;
      height: auto;
      margin: 1rem 0;
    }
  
  
  }

}

.static-page {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;

  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 250px, rgb(12, 27, 44) 350px, rgb(117, 145, 176) 450px, rgba(255, 255, 255, 1) 550px);
  background-size: 100%;
  background-repeat: no-repeat;

}
.static__imported {
  display: flex;
  flex-direction: column;
  height: 100%;
}