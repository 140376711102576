.cf {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-family: var(--font-fam-main);

  @media (min-width: 1200px) {
    padding: 1rem 0;
    margin: 0 auto;
  }

  &.h {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background-color: #e2e4e6;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;

    .form-row {
      display: flex;
      flex: 1 1 calc(100% / 3 - 2rem);
      padding: 0;


      &.textarea {
        width: 100%;
        height: 120px;
        flex: 1 0 auto;
        margin-top: 1rem;

        textarea {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 0.25rem;
          border: 1px solid #ddd;
          min-height: 120px;
        }

        span {
          display: inline-block;
          padding: 0.5rem 0 5px 1.5rem;
          margin-top: 0.5rem;
          min-width: 100px;
        }
      }

      &.bttn {
        width: 100%;
        height: 20px;
        flex: 1 0 auto;
        margin-top: 2rem;

      }

      label {
        flex: 1 1 33%;
        color: #222;

        @media (min-width: 1200px) {
          width: auto;
        }

        span {
          width: 80px;
          padding-bottom: 5px;
        }

        input {
          display: inline-block;
          width: 100%;
          height: 2.5rem;
          padding: 0.5rem;
          border: 1px solid #ddd;
        }

      }

    }

    .mighty {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0.75rem;
      background-color: black;
      color: white;
      font-size: 0.75rem;
      margin: 0;
      border: 0;
      cursor: pointer;
    }


  }

  .form-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 0.5rem 0;
    width: 100%;

    label {
      display: flex;
      width: 100%;
      flex-direction: column;
      font-size: 0.725rem;
      margin: 0 0 0.5rem 0;
      color: black;

      @media (min-width: 1200px) {
        width: 100%;
      }

      span {
        width: 120px;
      }

      .mighty {
        width: 120px;
        font-size: .75rem;
      }
    }

    input,
    textarea {
      display: block;
      width: 100%;
      padding: 1rem;
      border: 1px solid #ddd;
    }

    textarea {
      height: 100px;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }

  .mighty {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: rgba($color: #000, $alpha: 1.0);
    color: white;
    font-size: 0.875rem;
    margin: 1rem 0;
    border: 0;
    cursor: pointer;
  }


  // a big nice button

}