// header - same in all cases

.Works {
  position: relative;
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media (min-width: 1200px) {
    background-color: #d3d5d7;
  }

  .per-row-5 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    margin: 1rem 0;

    @media (min-width: 1200px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      padding: 2px 0;
      margin: 0;
      min-height: 300px;
    }

    @media (min-width: 1440px) {
      padding: 1.5rem 4rem;
    }
  
    @media (min-width: 1920px) {
      padding: 3rem 5rem;
      min-height: 440px;
    }
  }

  .pagination {
    position: relative;
    width: 100%;

    @media (min-width: 1200px) {
      position: absolute;
      top: 45%;
      z-index: 100;
    }
    
    .pagination--inner {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (min-width: 1440px){
        padding: 0 1rem;
      }

      .page-btn {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        width: 2.5rem;
        height: 2.5rem;
        margin: .5rem;
        color: black;
        background-color: white;
        aspect-ratio: 1/1;
        border: 2px solid black;
        border-radius: 50%;

        &.disabled {
          border: 0;
          background-color: transparent;
        }

        .material-symbols-outlined {
          margin: 0;
        }

        &.current-page {
          color: white;
          background-color: black;
        }

        &:hover {
          cursor: pointer;
          color: black;
          background-color: white;
        }
      }
    }
  }
}
