@import "./assets/css/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,300;0,400;0,500;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");

/* @import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap'); */


:root * {

  /* fonts */

  --font-fam-main: "Roboto Flex", sans-serif;
  --font-fam-deco: "Playfair Display", serif;
  
  --prima: crimson; 
  --accent: gold;

  /* shadows */

  --light-shadow: 0 1px 2px 0 rgba(58, 59, 73, 0.2),
    0 3px 8px 0 rgba(58, 59, 73, 0.05);
  --intense-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);

}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 32;

  &.small {
    font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 16;
  }
}

:root * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.414;
  color: var(--color-neutral-dark);
  -webkit-font-smoothing: antialiased;

  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}

#root {
  min-height: 100vh;
}

.app {
  width: 100%;
  font-size: 16px;
  font-size: 100%;
  font-size: 1rem;
  line-height: 1.333;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-fam-deco);
  font-weight: 400;
  line-height: 1;
  color: black;
}

