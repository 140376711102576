.sky {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  justify-content: center;
  max-width: 100%;

  .first {
    width: 100%;
    margin: 3rem 0 1rem 0;

    h2 {
      position: relative;
      top: 3px;
      margin-right: 1rem;
      display: inline-block;
    }

    .cloud {
      width: 100%;
      text-align: center;
    }
  }


  .row {
    display: none;

    @media (min-width: 1200px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 1rem;
    }
  }

  .location {
    width: 100%;

    @media (min-width: 1200px) {
      width: 50%;
    }
  }

  .time {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
      width: 50%;
      padding-left: 1rem;
    }
  }

  .season {
    width: 100%;
  }

  .year, .daytime {
    width: 100%;
    margin-top: 1rem;
  }


  h2 {
    color: white;
    margin: 1.5rem 0;
    font-size: 1.75rem;
  }
}