.single {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  background-color: #000;
  width: 100%;
  z-index: 3;


  &.full {
    .topbar {
      display: none;
    }
    .Footer {
      display: none;
    }
  }

  .topbar {
    border-bottom: 0;
  }
  
  @media (min-width: 1200px) {
    padding: 0 0 1.5 rem 0;
  }

  .work {
    position: relative;
    width: 100%;
    margin: 0 auto;
    flex: 1;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;

    .image-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 51;

      img {
        position: relative;
        width: auto;
        max-width: 100%;
        display: inline-block;
        transition: all 0.35s;  
        z-index: 52;
        box-shadow: var(--intense-shadow);
      }
    }
  }

  .Detail {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    z-index: 60;
    padding: 1rem 0 0;

    .inner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 61;
      
      @media (min-width: 1200px){
        flex-direction: row;
        justify-content: space-between; 
        
      }
    }

    .pa-de {
      font-family: var(--font-fam-deco);
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: .5rem;
      color: white;

      @media (min-width: 1200px){
        flex: 0 1 100%;
      }

      .smaller {
        display: inline-block;
        margin-left: 1rem;
        font-size: .875rem;
      }

      @media (min-width: 1200px) {
        
        font-size: 2rem;
        margin-bottom: 1rem;
        flex: 0 1 100%;
        flex-wrap: wrap;

        .smaller {
          display: inline-block;
          margin-left: 1rem;
          font-size: 1.2rem;
        }
  
      }
    }

  }

  .description {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    z-index: 70;

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }




  .terms {
    margin: .75rem .75rem 0 0;
    text-align: center;
    flex: 0 1 100%;


    .item {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 0.6875rem;
      padding: 2px 7px;
      border: 1px solid white;
      color: white;
      text-transform: uppercase;
      font-family: var(--font-fam-semi);
      font-weight: 300;
      background-color: black;

      &>a {
        display: inline-flex;
        color: white;
        padding: 0;
        width: 100%;
        height: 100%;

      }
    }
  }


  .ask {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-radius: 20px;
    border: 1px solid white;
    color: white;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;

  }

  .ask-form {
    display: none;

    &.open {
      display: flex;
      width: 100%;
      margin-top: 1rem;
    }
  }

  .buttons.q {
    position: relative;
    display: inline-flex;
    width: 3rem;
    top: 0;
    left: 0.5rem;
    z-index: 30;
  }


  .buttons {
    position: absolute;
    display: inline-flex;
    top: 0.75rem;
    justify-content: center;
    width: 120px;
    z-index: 300;
  }

  .edit-button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    a {
      position: relative;
      font-size: 1.5rem;
      color: #666;
      z-index: 1000;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #666;
    }
  }
}