
.neon--wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left:0;
  opacity: 1;
  z-index: 1000;
  background-color: #000;
  opacity: 0.9;
  z-index: 100;
}
.neon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;

  .neon--spinner {
    position: relative;
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    z-index: 100;
  }

  .neon--spinner::before,
  .neon--spinner::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 8px solid rgb(255, 213, 0);
    animation: prixClipFix 2s linear infinite;
  }

  .neon--spinner::after {
    border-color: #ffffff;
    animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
    inset: 16px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}
