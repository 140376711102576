.thumb {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: 0;
  text-align: center;
  transition: all 0.35s;
  margin-bottom: 1rem;

  &:hover a {
    font-size: 0.875rem;
    color: white;
  }

  &.alt {
    animation: appear-thumb 2.5 cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    .thumb__title {
      display: inline-block;
      opacity: 0;
      margin: 0.25rem 0 0 0;

      a {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
      }
    }

    img {
      animation: appear 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: all 0.35s;

  }


  @keyframes appear {
    0% {
      transform: translateX(180px) scale(3);
      opacity: 0;
    }

    49% {
      transform: translateX(180px) scale(3);
      opacity: 1;
    }

    92% {
      transform: translateX(-180px) scale(3);
      opacity: 1;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes appear-thumb {
    0% {
      background-color: #000;
      opacity: 0;
    }

    49% {
      background-color: #000;
      opacity: 1;
    }

    50% {
      background-color: #fff;
      opacity: 1;
    }
  }

  .thumb__title {
    display: none;

    @media (min-width: 1200px) {
      display: inline-block;
      font-size: 9px;
      margin: 0.25rem 0 0 0;

      &:hover a {
        width: 100%;
        height: 100%;
        font-size: 0.875rem;
        color: white;
      }
    }
  }

  @media (min-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    flex: 0 0 calc(20% - 2px);
    aspect-ratio: 1/1;
    padding: 1.25rem;
    background-color: #fff;
    margin: 0 2px 2px 0;
    overflow: hidden;

    &:hover {
      padding: 0;
      cursor: pointer;

      a {
        display: block;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
        filter: saturate(1.2) contrast(1.2);
        height: 100%;
        width: 100%;
      }

      .thumb__title {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        display: block;
        width: 100%;
        height: 20%;
        padding: 1rem;
        opacity: 1;
        background-color: #000;
        color: #fff;
        transition: all 0.5s;
        font-size: 1rem;
        font-family: var(--font-fam-deco);
      }
    }

  }

  @media (min-width: 1440px) {
    padding: 1.5rem;
  }

  @media (min-width: 1920px) {
    padding: 2rem;

    .thumb__title {
      position: relative;
      display: inline-block;
      margin-top: 6px;
      color: #000;
      font-size: 9px;
      text-align: center;
    }
  }
}