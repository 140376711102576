.Front {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  .prima {
    background-color: black;
    color: white;

    a {
      text-decoration: none;
      color: white;
    }
  }

  .secunda {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: black;
  }

  .tertia {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    background-color: black;
    padding-bottom: 2.5rem;

    a {
      text-decoration: none;
      color: white;
    }
  }

}






.Term {

  .prima {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: black;
    color: white;

    a {
      text-decoration: none;
      color: white;
    }


    p {
      text-align: center;
    }
  }

  .secunda {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: black;
  }

  .tertia {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: black;
    padding-bottom: 2.5rem;

    a {
      text-decoration: none;
      color: white;
    }
  }
}
