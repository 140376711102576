.topbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.25rem;
  z-index: 122;
  background-color: #000;
  color: #fff;
  border-bottom: 1px solid #444;

  .topbar--inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;  
    justify-content: space-between;
  }

  .burger {
    padding: 0.75rem 0 0.5rem 0;
  }

  .logo {
    display: inline-flex;
    position: relative;
    height: 100%;
    padding: 1.25rem 0 0.75rem;  
    color: #fff;

    a {
      color: #fff;
    }
  }
}



.burger {
  position: relative;
  display: block;
  top: 3px; 
  
  display: inline-flex;
  margin-right: .75rem;
  z-index: 200;

  &>.material-symbols-outlined {
    font-size: 2rem;    
    color: #fff;
  }


  &:hover {
    cursor: pointer;
  }
}



.logo {
  position: relative;
  display: inline-flex;
  font-family: var(--font-fam-deco);
  font-size: 1.5rem;
  line-height: 1;
  margin: 0 0 0 1.5rem;

  &:hover {
    cursor: pointer;
  }
}
