
.page {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  z-index: 1;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 2;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1.5rem 0 1rem;

}

.box-shadow-gentle {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);   
}

.hidden {
  display: none;
}


.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 1rem 0;

  i {
    position: relative;
    top: 0.5rem;
    margin: 0 0.5rem;
    font-size: 2.25rem;
  }
}
.social-svg-mask {
  fill: black !important;
}

.social-icon {
  width: 2rem !important;
  margin: 0 .5rem 0 1rem;
}


.item {
  margin: 0 1rem 1rem 0;

  a {
    color: white;
    padding: 0.75rem 1.5rem;
  }
}


.buttons {
  position: relative;
  display: inline-flex;

  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2.25rem;
    width: 2rem;
    height: 2rem;
    margin: 0 5px;
    padding: 5px;
    color: black;
    border-radius: 50%;
    background-color: white;

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 0, 0, 0.5);
    }
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6375rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0;
  color: white;
  background-color: var(--prima);
  text-transform: uppercase;
  border-radius: 5px;

  &:hover {
    color: black;
  }

}


.terms .item,
.terms .item.d-on-l {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  color: white;
  margin: 0 0.5rem 0.5rem 0;
  text-transform: uppercase;
  border: 1px solid white;

  &:hover {
    color: white;
    background-color: black;
  }

  &>a {
    color: white;
    padding: 0.25rem 0.75rem;
    
    &:hover {
      color: black;
      background-color: white;
    }
  }
}

.highlighted {
  display: inline-block;
  border-bottom: 2px dotted  white;
}
