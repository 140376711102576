.ken {

  .ken-bio {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 1rem;
    color: white;
    margin: 2rem 0;

    .ken-photo {
      width: 60%;
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    @media (min-width: 1200px) {
      flex-direction: row;
      padding: 0;
      width: 100%;
      max-width: 1200px;
      margin: 2rem auto;


      .ken-photo {
        flex: 0 0 240px;
        display: block;
        width: 240px;
        height: auto;
        padding: 1rem 2rem 1rem 0;

        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
        }
      }

      .ken-text {
        flex: 0 1 calc(100% - 240px - 2rem);
      }
    }
  }
}