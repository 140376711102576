.FrontHeader {
  position: relative;

  
  .header {
    position: relative;
    padding: 1.5rem 1rem;
    width: 100%;
    z-index: 300;

    @media (min-width: 1200px) {
      padding: 2rem 0;
      left: 0;
      width: 100%;        
    }

    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.25;
      margin: 0 0 .5rem;
      color: white;
      text-align: center;

      &.smaller {
        font-size: 1.125rem;
      }

      @media (min-width: 1200px) {
        font-size: 2rem;
        margin: 0 0 0.5rem;

        &.smaller {
          font-size: 1.5rem;
        }
      }
      @media (min-width: 1920px) {
        font-size: 3rem;
      }

    }

  }
}

.prima {
  background-color: black;
  color: white;

  a {
    text-decoration: none;
    color: white;
  }
}
